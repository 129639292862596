// 帮助接口
import service from ".."

// 帮助数据
export function help (params) {
  return service({
    method: 'GET',
    url: "/message/help",
    params
  })
}