import { createRouter, createWebHistory } from 'vue-router'
const Home = () => import('../views/Home.vue')
const Notice = () => import('../views/Notice.vue')
const Help = () => import('../views/Help.vue')
const OrderSet = () => import('../views/OrderSet.vue')
const MyHome = () => import('../views/MyHome.vue')
const Bank = () => import('../views/Bank.vue')
const Bill = () => import('../views/Bill.vue')
const Shop = () => import('../views/Shop.vue')
const Market = () => import('../views/Market.vue')
const Booth = () => import('../views/Booth.vue')
const ClosingOrder = () => import('../views/ClosingOrder.vue')
const Give = () => import('../views/Give.vue')
const GiveOrder = () => import('../views/GiveOrder.vue')
const Login = () => import('../views/Login.vue')
const LoginTip = () => import('../views/LoginTip.vue')
const WithDraw = () => import('../views/WithDraw.vue')
const WithDrawRecord = () => import('../views/WithDrawRecord.vue')
const Collect = () => import('../views/Collect.vue')
const CollectOrder = () => import('../views/CollectOrder.vue')
const Lucky = () => import('../views/Lucky.vue')
const MyPart = () => import('../views/MyPart.vue')
const LuckyRecord = () => import('../views/LuckyRecord.vue')
const FSXLRecord = () => import('../views/FSXLRecord.vue')
const FSXRecord = () => import('../views/FSXRecord.vue')
const DiamonRecord = () => import('../views/DiamonRecord.vue')

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/notice/:type',
    name: 'notice',
    component: Notice
  },
  {
    path: '/help',
    name: 'help',
    component: Help
  },
  {
    path: '/order',
    name: 'order',
    component: OrderSet
  },
  {
    path: '/myHome',
    name: 'myHome',
    component: MyHome
  },
  {
    path: '/bank',
    name: 'bank',
    component: Bank
  },
  {
    path: '/bill',
    name: 'bill',
    component: Bill
  },
  {
    path: '/shop',
    name: 'shop',
    component: Shop
  },
  {
    path: '/market',
    name: 'market',
    component: Market
  },
  {
    path: '/booth',
    name: 'booth',
    component: Booth
  },
  {
    path: '/closingOrder',
    name: 'closingOrder',
    component: ClosingOrder
  },
  {
    path: '/give',
    name: 'give',
    component: Give
  },
  {
    path: '/giveOrder',
    name: 'giveOrder',
    component: GiveOrder
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/loginTip',
    name: 'loginTip',
    component: LoginTip
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    component: WithDraw
  },
  {
    path: '/withdrawRecord',
    name: 'withdrawRecord',
    component: WithDrawRecord
  },
  {
    path: '/collect',
    name: 'collect',
    component: Collect
  },
  {
    path: '/collectOrder',
    name: 'collectOrder',
    component: CollectOrder
  },
  {
    path: '/lucky',
    name: 'lucky',
    component: Lucky
  },
  {
    path: '/myPart',
    name: 'myPart',
    component: MyPart
  },
  {
    path: '/luckyRecord',
    name: 'luckyRecord',
    component: LuckyRecord
  },
  {
    path: '/fsxlRecord',
    name: 'fsxlRecord',
    component: FSXLRecord
  },
  {
    path: '/fsxRecord',
    name: 'fsxRecord',
    component: FSXRecord
  },
  {
    path: '/diamonRecord',
    name: 'diamonRecord',
    component: DiamonRecord
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
