// 首页接口
import service from ".."

// 首页数据
export function farmInfo (params) {
  return service({
    method: 'GET',
    url: "/farm/info",
    params
  })
}

// 访问农场
export function farmVisit (params) {
  return service({
    method: 'GET',
    url: "/farm/visit",
    params
  })
}

// 收获作物
export function harvestCrop (params) {
  return service({
    method: 'GET',
    url: "/farm/harvest_crop",
    params
  })
}

// 播种作物
export function dealCrop (params) {
  return service({
    method: 'GET',
    url: "/farm/deal_crop",
    params
  })
}