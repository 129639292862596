// 交易市场接口
import service from ".."

// 交易市场数据
export function tradeInfo (params) {
  return service({
    method: 'GET',
    url: "/trade/info",
    params
  })
}

// 我的摊位数据
export function tradeMyOrder (params) {
  return service({
    method: 'GET',
    url: "/trade/my_order",
    params
  })
}

// 成交订单数据
export function tradeOrders (params) {
  return service({
    method: 'GET',
    url: "/trade/orders",
    params
  })
}

// 挂单-出售-购买
export function pendOrder (params) {
  return service({
    method: 'GET',
    url: "/trade/pend_order",
    params
  })
}

// 撤单
export function cancelOrder (params) {
  return service({
    method: 'GET',
    url: "/trade/cancel_order",
    params
  })
}

// 收单
export function dealOrder (params) {
  return service({
    method: 'GET',
    url: "/trade/deal_order",
    params
  })
}


// 挂单内容
export function prePendOrder (params) {
  return service({
    method: 'GET',
    url: "/trade/pre_pend_order",
    params
  })
}