// 提现接口
import service from ".."

// 提现数据
export function withdrawInfo (params) {
  return service({
    method: 'GET',
    url: "/withdraw/info",
    params
  })
}

// 确认提现
export function withdrawDeal (params) {
  return service({
    method: 'GET',
    url: "/withdraw/deal",
    params
  })
}

// 发送验证码
export function smsSend (params) {
  return service({
    method: 'GET',
    url: "/sms/send",
    params
  })
}

// 提现记录
export function withdrawLogs (params) {
  return service({
    method: 'GET',
    url: "/withdraw/logs",
    params
  })
}