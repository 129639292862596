// 个人中心接口
import service from ".."

// 个人中心数据
export function ucenterInfo (params) {
  return service({
    method: 'GET',
    url: "/ucenter/info",
    params
  })
}

// 上链待选项
export function farmScripToChain (params) {
  return service({
    method: 'GET',
    url: "/finance/farm_scrip_to_chain",
    params
  })
}

// 确定上链
export function dealFarmScripToChain (params) {
  return service({
    method: 'GET',
    url: "/finance/deal_farm_scrip_to_chain",
    params
  })
}

// 农场融资选项
export function chainToFarmScrip (params) {
  return service({
    method: 'GET',
    url: "/finance/chain_to_farm_scrip",
    params
  })
}

// 确定融资
export function dealChainToFarmScrip (params) {
  return service({
    method: 'GET',
    url: "/finance/deal_chain_to_farm_scrip",
    params
  })
}

// 签到
export function dealSign (params) {
  return service({
    method: 'GET',
    url: "/ucenter/deal_sign",
    params
  })
}

// 兑换钻石选项
export function scripExchangeDiamond (params) {
  return service({
    method: 'GET',
    url: "/finance/scrip_exchange_diamond",
    params
  })
}


// 立即兑换
export function dealScripExchangeDiamond (params) {
  return service({
    method: 'GET',
    url: "/finance/deal_scrip_exchange_diamond",
    params
  })
}

// 购买藏品
export function nftBuy (params) {
  return service({
    method: 'GET',
    url: "/nft/buy",
    params
  })
}

// fxsl记录
export function logFsxl (params) {
  return service({
    method: 'GET',
    url: "/ucenter/log_fsxl",
    params
  })
}

// fxs记录
export function logFsx (params) {
  return service({
    method: 'GET',
    url: "/ucenter/log_fsx",
    params
  })
}

// 钻石记录
export function logDiamond (params) {
  return service({
    method: 'GET',
    url: "/ucenter/log_diamond",
    params
  })
}