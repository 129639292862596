import { Col, Row, Image, Dialog, Field, RadioGroup, Radio, CountDown, Toast, List, Overlay, Loading } from 'vant'
// 放到数组中
let plugins = [
  Col,
  Row,
  Image,
  Dialog,
  Field,
  RadioGroup,
  Radio,
  CountDown,
  List,
  Overlay,
  Loading
]
export default function getVant (app) {
  app.config.globalProperties.$toast = Toast
  plugins.forEach(item => {
    return app.use(item)
  })
}