import axios from 'axios'
const t = Math.floor(Math.random() * 13 * 1000000000000)
let service = axios.create({
  baseURL: 'https://i.farms.xin',
  timeout: 3000
})


// 添加请求拦截器
service.interceptors.request.use((config) => {
  // const token = getToken()
  // if (token) {
  //   config.headers.Authorization = `Bearer ${token}`
  // }
  const ukey = localStorage.getItem('farmUkey')
  config.params = { ukey, ...config.params, t }
  return config
}, (error) => {
  return Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use((response) => {
  return response.data
}, (error) => {
  return Promise.reject(error)
})

export default service