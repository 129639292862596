// 播种设置接口
import service from ".."

// 播种设置数据
export function plantSetup (params) {
  return service({
    method: 'GET',
    url: "/farm/plant_setup",
    params
  })
}

// 设置播种
export function dealPlantSetup (params) {
  return service({
    method: 'GET',
    url: "/farm/deal_plant_setup",
    params
  })
}