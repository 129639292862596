// 商店接口
import service from ".."

// 商店数据
export function shop (params) {
  return service({
    method: 'GET',
    url: "/farm/shop",
    params
  })
}

// 购买种子
export function dealBuy (params) {
  return service({
    method: 'GET',
    url: "/farm/deal_buy",
    params
  })
}

// 出售作物
export function dealSell (params) {
  return service({
    method: 'GET',
    url: "/farm/deal_sell",
    params
  })
}

// 制作菜谱
export function dealKitchen (params) {
  return service({
    method: 'GET',
    url: "/farm/deal_kitchen",
    params
  })
}

// nft
export function dealNft (params) {
  return service({
    method: 'GET',
    url: "/farm/deal_nft",
    params
  })
}

// 刷新种子
export function dealRefresh (params) {
  return service({
    method: 'GET',
    url: "/farm/deal_refresh",
    params
  })
}
