import service from ".."
// 获取图形验证码
export function captcha () {
  return service({
    method: 'GET',
    url: "/captcha",
  })
}


// 获取手机验证码
export function smsDeal (params) {
  return service({
    method: 'GET',
    url: "/sms/deal",
    params
  })
}

// 登录
export function registerLogin (params) {
  return service({
    method: 'GET',
    url: "/users/register_login",
    params
  })
}


// 修改昵称头像
export function setHead (params) {
  return service({
    method: 'GET',
    url: "/ucenter/set_head",
    params
  })
}
