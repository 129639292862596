import { createStore } from 'vuex'
import { farmInfo, farmVisit } from '@/request/api/index.js'

export default createStore({
  state: {
    uinfo: {},
    plant_setting: [],
    areas: [],
    bankRule: [],
    isShowVisiter: false,
    visiterInfo: {},
    myInfo: {},
    farm_nft: [],
    farm_id: '',
    system: ''
  },
  getters: {
  },
  mutations: {
    updateMyInfo (state, value) {
      state.myInfo = value
    },
    updateFarmInfo (state, farmInfo) {
      state.uinfo = farmInfo.uinfo
      state.plant_setting = farmInfo.plant_setting
      state.areas = farmInfo.areas
      state.farm_nft = farmInfo.farm_nft
      state.farm_id = farmInfo.uinfo.farm_id
      state.system = farmInfo.system
    },
    updateBankRule (state, value) {
      state.bankRule = value
    },
    updateIsShowVisiter (state, value) {
      state.isShowVisiter = value
    },
    updateVisiterInfo (state, value) {
      state.visiterInfo = value
    }
  },
  actions: {
    async getFarmInfo (context, params) {
      const res = await farmInfo(params)
      if (res.code == 1) {
        if (res.data.farm_id == 0) {
          this.$router.replace('/loginTip')
        } else {
          context.commit('updateFarmInfo', res.data)
          context.commit('updateMyInfo', res.data)
        }
      } else {
        // this.$toast({ message: res.msg })
      }
    },

    async getVisitInfo (context, params) {
      const res = await farmVisit(params)
      if (res.code == 1) {
        context.commit('updateVisiterInfo', res.data)
        context.commit('updateFarmInfo', res.data)
        context.commit('updateIsShowVisiter', true)
        return res
      } else {
        return res
      }
    }
  },
  modules: {
  }
})
