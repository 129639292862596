<template>
  <router-view />
</template>

<style lang="scss">
body {
  width: 100vw;
  height: 100vh;
  background: url("@/assets/bg-bottom.png") bottom left no-repeat,
    url("@/assets/body-bg.png") top left no-repeat;
  background-size: 617px 348px, 100% 100%;
  overflow: hidden;
  div,
  ul,
  h6,
  p {
    box-sizing: border-box;
  }
  h6,
  ul,
  p {
    margin: 0;
  }
  .notice-dialog {
    top: 50%;
    width: 660px;
    background: #f6ebcb;
    border-radius: 20px;
    border: 5px solid #ab7c57;
    padding: 48px 0 91px;
    overflow: initial;
    &.lucky {
      padding-bottom: 64px;
    }
    &.address {
      padding-bottom: 0;
    }
    &.money {
      padding-bottom: 81px;
      .van-dialog__header {
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #fefefe;
      }
    }
    &.myHome {
      padding-bottom: 37px;
      .van-dialog__header {
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #fefefe;
      }
    }
    &.msg {
      &.help {
        .help-content {
          font-size: 26px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #aa3601;
          line-height: 40px;
          margin: 24px 60px 0;
          max-height: 600px;
          overflow-y: auto;
        }
      }
      .van-dialog__header {
        width: 100%;
        height: auto;
        background: none;
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #85482c;
        line-height: 36px;
      }
      .split-line {
        width: 598px;
        height: 4px;
        background: url(@/assets/notice-help/modal-split.png) no-repeat;
        background-size: 100% 100%;
        margin: 49px auto 0;
      }
      .msg-status {
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #aa3601;
        line-height: 40px;
        margin: 33px 0 0 61px;
      }
      .msg-info {
        width: 609px;
        background: #fffaed;
        padding: 24px 35px;
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #aa3601;
        line-height: 40px;
        margin: 25px auto 0;
        & > li {
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .avatar-icon {
          width: 62px;
          height: 62px;
          vertical-align: middle;
        }
      }
      .van-button {
        width: 424px;
        height: 83px;
        background: url(@/assets/notice-help/modal-btn.png) no-repeat;
        background-size: 100% 100%;
        font-size: 0;
        margin: 126px auto 0;
        flex: initial;
      }
    }
    &.refresh,
    &.market {
      .van-dialog__header {
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #fefefe;
      }
    }
    &.market {
      .van-field__control {
        text-align: center !important;
      }
      .visit-input {
        .van-field__control {
          font-size: 28px !important;
          font-family: PingFang SC;
          font-weight: 400 !important;
          color: #85482c !important;
          line-height: 70px;
        }
      }
      .input-box.dialog {
        .van-field__control {
          color: #85482c !important;
        }
      }
    }
    &.refresh {
      .van-field__control {
        font-size: 24px !important;
      }
    }
    .van-dialog__header {
      width: 425px;
      height: 67px;
      background: url(@/assets/modal-title.png) no-repeat;
      background-size: 100% 100%;
      margin: 0 auto;
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 67px;
      padding: 0;
    }
    .notice-dialog-main {
      width: 600px;
      height: 580px;
      background: #fcf7d7;
      border: 5px solid #f0c350;
      border-radius: 20px;
      margin: 34px auto 0;
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #aa3601;
      line-height: 40px;
      padding: 24px;
      overflow-y: auto;
      &.myHome {
        margin-top: 55px;
        height: auto;
        position: relative;
        overflow: initial;
        padding: 65px 15px 31px;
      }
    }
    .close-btn {
      cursor: pointer;
      width: 70px;
      height: 70px;
      right: -20px;
      top: -20px;
      font-size: 0;
      position: absolute;
      background: url(@/assets/notice-help/modal-close.png) no-repeat;
      background-size: 100% 100%;
    }
    .myHome-dialog-title {
      width: 520px;
      height: 77px;
      background: url(@/assets/myHome/dialog-title.png) no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: -31px;
      left: 40px;
      text-align: center;
      line-height: 31px;
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #aa3601;
      padding-top: 24px;
      span,
      img {
        vertical-align: middle;
      }
      img {
        width: 40px;
        height: 40px;
        margin-left: 1px;
      }
    }
  }

  .van-toast {
    width: auto;
    max-width: 600px;
  }

  .bank-num {
    .van-field__control {
      font-size: 30px;
      font-family: PingFang SC;
      color: #85482c;
      line-height: 40px;
      &:disabled {
        color: #85482c;
        -webkit-text-fill-color: #85482c;
      }
    }
  }

  .radios-box {
    height: 60px;
    .van-radio__icon {
      height: 36px;
    }
    .van-radio--horizontal {
      margin-right: 78px;
    }
  }
  .normal-input {
    background: transparent;
    height: 69px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 22px;
    &.mini {
      width: 254px;
      background: url(@/assets/withdraw/input-bg.png) no-repeat;
      background-size: 100% 100%;
    }
    .van-field__control {
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #85482c;
    }
  }
  .normal-textarea {
    background: transparent;
    height: 169px;
    padding: 22px;
    .van-field__control {
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #85482c;
    }
  }
}
.van-count-down {
  font-size: 26px !important;
}
.van-overlay {
  z-index: 5 !important;
}
.van-toast__text,
.van-list__finished-text {
  font-size: 26px !important;
  line-height: 30px !important;
}
.van-field-5-input {
  font-size: 26px !important;
}
.van-icon-fail {
  font-size: 0 !important;
}
</style>
