// 公告接口
import service from ".."

// 公告数据
export function messageNotice (params) {
  return service({
    method: 'GET',
    url: "/message/notice",
    params
  })
}

// 公告详情
export function messageDetail (params) {
  return service({
    method: 'GET',
    url: "/message/detail",
    params
  })
}

// 消息中心
export function messageUser (params) {
  return service({
    method: 'GET',
    url: "/message/user",
    params
  })
}