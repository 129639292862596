// 赠送接口
import service from ".."

// 赠送数据
export function transferInfo (params) {
  return service({
    method: 'GET',
    url: "/transfer/info",
    params
  })
}

// 赠送订单数据
export function myOrder (params) {
  return service({
    method: 'GET',
    url: "/transfer/my_order",
    params
  })
}



// 订单详情
export function orderInfo (params) {
  return service({
    method: 'GET',
    url: "/transfer/order_info",
    params
  })
}


// 搜索
export function search (params) {
  return service({
    method: 'GET',
    url: "/transfer/search",
    params
  })
}


// 赠送
export function order (params) {
  return service({
    method: 'GET',
    url: "/transfer/order",
    params
  })
}

// 历史
export function history (params) {
  return service({
    method: 'GET',
    url: "/transfer/history",
    params
  })
}