// 藏品接口
import service from ".."

// 藏品数据
export function nftInfo (params) {
  return service({
    method: 'GET',
    url: "/nft/info",
    params
  })
}

// 藏品订单
export function nftOrders (params) {
  return service({
    method: 'GET',
    url: "/nft/orders",
    params
  })
}