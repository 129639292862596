import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import getVant from './components/plugins'
import 'lib-flexible/flexible.js'
import 'vant/lib/index.css'
import 'vant/es/toast/style'
import 'vant/es/image-preview/style'
import VueClipBoard from 'vue-clipboard2'


const app = createApp(App)
getVant(app)
app.use(VueClipBoard)
app.use(store).use(router).mount('#app')
