// 银行接口
import service from ".."

// 银行数据
export function financeInfo (params) {
  return service({
    method: 'GET',
    url: "/finance/info",
    params
  })
}

// 我要存钱信息
export function prestore (params) {
  return service({
    method: 'GET',
    url: "/finance/prestore",
    params
  })
}

// 我要存钱提交
export function dealStore (params) {
  return service({
    method: 'GET',
    url: "/finance/deal_store",
    params
  })
}

// 我的账单
export function recordsStore (params) {
  return service({
    method: 'GET',
    url: "/finance/records_store",
    params
  })
}

// 提取存款
export function deposits (params) {
  return service({
    method: 'GET',
    url: "/finance/deposits",
    params
  })
}