// 抽奖接口
import service from ".."

// 数据
export function farmLuckInfo (params) {
  return service({
    method: 'GET',
    url: "/farm_luck/info",
    params
  })
}

// 抽奖
export function farmLuckDeal (params) {
  return service({
    method: 'GET',
    url: "/farm_luck/deal",
    params
  })
}

// 抽奖记录
export function farmLuckLogs (params) {
  return service({
    method: 'GET',
    url: "/farm_luck/logs",
    params
  })
}

// 我的碎片
export function farmLuckFragment (params) {
  return service({
    method: 'GET',
    url: "/farm_luck/fragment",
    params
  })
}